<template>
	<div class="setting-page">
		<div class="add-btn-box">
			<a-button type="primary" @click="showAdd">
				<a-icon type="plus-circle" />添加
			</a-button>
		</div>
		<div class="setting-wrapper">
			<a-table
				:loading="dataLoading"
				:rowKey="(item,index) => {return index}" 
				:columns="columns"
				:data-source="list"
				:bordered="true"
				:pagination="{hideOnSinglePage: true, current: page.current, total: page.total}">
				<template slot="color" slot-scope="index, item">
					<div class="tag-name">
						<div class="bg" :style="{background: item.color}"></div>
						<div class="te" :style="{color: item.color}">{{item.park_tag_name}}</div>
					</div>
				</template>
				<template slot="operation" slot-scope="index, item">
					<a-button size="small" type="danger" @click="toggleShow(item.is_show)">删除</a-button>
				</template>
			</a-table>
		</div>
		
		<a-modal v-model="show"
			:title="edit_id?'修改标签':'添加标签'"
			okText="提交" 
			cancelText="取消"
			@ok="confirmTag"
			:confirmLoading="editIng"
			@cancel="cancelTag"
		>
			<a-input type="text" v-model="form.park_tag_name" placeholder="请输入名字" />
			
			<div class="color-bq">选择标签颜色</div>
			<div class="color-box">
				<div class="item" :class="{act : form.color == item.value}" @click="setColor(item.value)" v-for="(item,index) in colorOption" :key="index" :style="{background: item.value}"></div>
			</div>
		</a-modal>
		
	</div>
</template>

<script>
	export default {
		data() {
			let columns = [
				{
					title:'序号',
					customRender: (text, record, index) => `${(this.page.current-1) * 10 + (index+1)}`
				},
				{
					title: '名称',
					key: 'park_tag_name',
					dataIndex: 'park_tag_name'
				},
				{
					title: '颜色',
					key: 'color',
					scopedSlots: { customRender: 'color' }
				},
				{
					title: '操作',
					key: 'operation',
					scopedSlots: { customRender: 'operation' }
				}
			];
			return {
				visible: false,
				editIng: false,
				show: false,
				columns,
				dataLoading: false,
				list: [],
				edit_id:'',
				form:{
					park_tag_name:'',
					color:'',
				},
				curParams:{},
				page:{
					current: 1,
					total: 0
				},
				curType: '',
				colorOption:[
					{value: '#1CD7BA'},
					{value: '#17BFA5'},
					{value: '#2FCCDB'},
					{value: '#2DBBFF'},
					{value: '#4F8AF9'},
					{value: '#6F77FA'},
					{value: '#C272EF'},
					{value: '#EF7EDD'},
					{value: '#F968AA'},
					{value: '#FA5C56'},
					{value: '#FF7748'},
					{value: '#FEA419'},
					{value: '#FED131'},
					{value: '#9AD559'},
					{value: '#67C060'},
					{value: '#3ABA5E'}
				]
			}
		},
		computed: {},
		created() {
			
			this.loadData()
		},
		mounted() {
		},
		methods:{
			getList() {
				let t = this
				t.$post('parkTagList',{limit: 9999}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			loadData() {
				this.getList()
			},
			showAdd() {
				this.show = true
			},
			confirmTag() {
				let t = this
				
				let param = {}
				if(!t.form.park_tag_name) {
				 	return t.$message.warn('请输入标签名', 1.5)
				}
				if(!t.form.color) {
				 	return t.$message.warn('请选择颜色值', 1.5)
				}
				t.editIng = true
				t.$post('parkTagAdd',t.form).then(res => {
					let { code, data, msg } = res
					t.editIng = false
					if (code == 0) {
						t.$message.success(msg, 1.5)
						t.getList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.editIng = false
				})
			},
			cancelTag() {
				this.show = false
				this.edit_id = ''
			},
			setColor(val) {
				this.form.color = val
			}
		}
	}
</script>

<style lang="less">
	.setting-page {}
	.color-bq{
		margin-top: 2%;
	}
	.color-box {
		display: flex;
		flex-wrap: wrap;
		margin-top: 2%;
		.item {
			width: 10.75%;
			margin-right: 2%;
			margin-bottom: 2%;
			border-radius: 5px;
			cursor: pointer;
			transition: all .2s;
			&::after{
				border-radius: 5px;
				content: "";
				display: block;
				width: 100%;
				padding-top: 100%;
				pointer-events: none;
			}
			&:nth-child(8n) {
				margin-right: 0;
			}
			
			
		}
		.act {
			transform: scale(1.1);
		}
	}
	.tag-name {
		width: 80px;
		height: 24px;
		border-radius: 4px;
		position: relative;
		.bg{
		border-radius: 4px;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: .3;
		}
		.te{
			line-height: 24px;
			text-align: center;
		}
	}
</style>
